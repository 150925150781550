import { UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import { DeleteWorkspaceUserContactCommand } from '@indigohive/cogfy-types/endpoints/deleteWorkspaceUserContact'
import { useTranslation } from 'react-i18next'
import { Button, Dialog } from '../../../components'
import { useCogfy } from '../../../hooks'

export type DeleteWorkspaceUserContactDialogProps = {
  open: boolean
  onClose: () => void
  userContactId?: UUID
  onSuccess?: () => void
}

export function DeleteWorkspaceUserContactDialog (props: DeleteWorkspaceUserContactDialogProps) {
  const { open, onClose, userContactId, onSuccess } = props

  const { t } = useTranslation()
  const cogfy = useCogfy()
  const deleteUserContact = useMutation({
    mutationFn: (data: DeleteWorkspaceUserContactCommand) => cogfy.deleteWorkspaceUserContact(data),
    onSuccess
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      {t('WorkspaceAssistantPage:Delete contact?')}
      <div className="modal-action">
        <Button
          size="sm"
          type="button"
          color="primary"
          disabled={deleteUserContact.isPending}
          onClick={() => userContactId && deleteUserContact.mutate({ userContactId })}
        >
          {t('Confirm')}
        </Button>
      </div>
    </Dialog>
  )
}

import { connectionDefinitionByProvider } from '@indigohive/cogfy-common'
import { GetConnectionsResultData, ProviderName, UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useState } from 'react'
import { Button, Heading, Menu, OverlayContent, Toggle } from '../../../components'
import { useCogfy, usePermissions } from '../../../hooks'
import { AddFacebookButton } from './AddFacebookButton'
import { AddTwilioSendGridButton } from './AddTwilioSendGridButton'
import { AddOpenAIButton } from './AddOpenAIButton'
import { AddHttpButton } from './AddHttpButton'
import { AddAwsButton } from './AddAwsButton'
import { EllipsisIcon } from 'lucide-react'

const logoByProvider: Partial<Record<ProviderName, string>> = {
  anthropic: '/anthropic-avatar.png',
  aws: '/aws-bedrock-avatar.png',
  openai: '/openai-avatar.png',
  sendgrid: '/sendgrid-avatar.png',
  unstructured: '/unstructured-avatar.png',
  whatsapp: '/whatsapp-avatar.png'
}

export type ConnectionSectionProps = {
  provider: ProviderName
  connections: GetConnectionsResultData[]
  onRefetch?: () => void
}

export function ConnectionSection (props: ConnectionSectionProps) {
  const { provider, connections, onRefetch } = props
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const permissions = usePermissions()
  const [openConnection, setOpenConnection] = useState<UUID | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [connectionToEdit, setConnectionToEdit] = useState<GetConnectionsResultData | null>(null)

  const definition = connectionDefinitionByProvider[provider]
  const isAdmin = permissions.isAdmin
  const hasConnections = connections.length > 0
  const updateDefault = useMutation({
    mutationFn: async ({ _default }: { _default: boolean }) => {
      if (!connectionToEdit) return
      await cogfy.connections.updateDefault(connectionToEdit.id, { default: _default })
      setAnchorEl(null)
      setConnectionToEdit(null)
      props.onRefetch?.()
    }
  })
  const updateEnabled = useMutation({
    mutationFn: async ({ connectionId, enabled }: { connectionId: UUID, enabled: boolean }) => {
      await cogfy.connections.updateEnabled(connectionId, { enabled })
      props.onRefetch?.()
    }
  })

  return (
    <div className="w-1/3 min-w-72 p-6 rounded-2xl bg-background-default">
      <div className="flex items-center px-2 mb-4">
        {logoByProvider[provider] && (
          <img
            src={logoByProvider[provider]}
            alt={`${definition?.label}-logo`}
            className="w-8 rounded-md"
          />
        )}
        <Heading id={provider} component='h2' className="pl-4 grow">
          {definition?.label ?? provider}
        </Heading>
        {provider === 'aws' && (<AddAwsButton onSuccess={onRefetch} />)}
        {provider === 'http' && (<AddHttpButton onSuccess={onRefetch} />)}
        {provider === 'openai' && (<AddOpenAIButton onSuccess={onRefetch} />)}
        {provider === 'sendgrid' && (<AddTwilioSendGridButton onSuccess={onRefetch} />)}
        {provider === 'whatsapp' && (<AddFacebookButton onSuccess={onRefetch} />)}
      </div>
      {!hasConnections && (
        <div className="text-sm px-2">
          {t('WorkspaceConnectionsPage:No connections set')}
        </div>
      )}
      {hasConnections && (
        <div className="flex flex-col gap-2">
          {connections.map(connection => (
            <div
              key={connection.id}
              tabIndex={0}
              className={clsx(
                'card',
                'card-bordered',
                'collapse',
                'bg-base-100',
                'border border-border-default',
                openConnection === connection.id ? 'collapse-open' : 'collapse-close h-16'
              )}
            >
              <div
                className="font-medium flex items-center cursor-pointer"
                onClick={() => setOpenConnection(openConnection === connection.id ? null : connection.id)}
              >
                <div className="flex items-center justify-between w-full px-4">
                  <div className="flex items-center gap-2">
                    <Toggle
                      color="primary"
                      checked={connection.enabled}
                      disabled={updateEnabled.isPending}
                      onChange={event => {
                        updateEnabled.mutate({ connectionId: connection.id, enabled: event.target.checked })
                      }}
                      onClick={event => event.stopPropagation()}
                    />
                    <div className={clsx('grow', !connection.enabled && 'opacity-40')}>
                      {connection.name}
                      &nbsp;
                      {connection.default && (
                        <span className="text-xs opacity-40">
                          ({t('Default')})
                        </span>
                      )}
                    </div>
                  </div>
                  {isAdmin && (
                    <Button
                      ghost
                      square
                      size="sm"
                      onClick={event => {
                        event.stopPropagation()
                        setAnchorEl(event.currentTarget)
                        setConnectionToEdit(connection)
                      }}
                    >
                      <EllipsisIcon size={20} />
                    </Button>
                  )}
                </div>
              </div>
              <div className="collapse-content">
                <ul>
                  {connection.values.map(value => (
                    <li key={value.id} className="flex items-center">
                      <span>{value.name}: </span>
                      <span>{value.plainTextValue ?? '********'}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
      <OverlayContent
        open={Boolean(connectionToEdit)}
        onClose={() => {
          setAnchorEl(null)
          setConnectionToEdit(null)
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Menu className={clsx(connectionToEdit?.default && '[&_li>*:not(ul):not(.menu-title):not(details):active]:bg-inherit')}>
          <li className={clsx(connectionToEdit?.default && 'disabled')}>
            <a
              className="font-bold"
              onClick={() => updateDefault.mutate({ _default: true })}
            >
              {t('WorkspaceConnectionsPage:Set as default')}
            </a>
          </li>
        </Menu>
      </OverlayContent>
    </div>
  )
}

import {
  isEqualsFilter,
  isGreaterThanFilter,
  isGreaterThanOrEqualsFilter,
  isHasErrorFilter, isIsEmptyFilter,
  isIsPendingFilter,
  isLessThanFilter,
  isLessThanOrEqualsFilter,
  isNotEqualsFilter
} from '@indigohive/cogfy-common'
import { RecordFilter, SelectFieldData, SelectFieldDataOption } from '@indigohive/cogfy-types'
import { format, isThisYear } from 'date-fns'
import { ptBR, enUS } from 'date-fns/locale'
import { CollectionStateField } from '../../../lib'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

export function formatFilterLabel (filter: RecordFilter, field: CollectionStateField) {
  const { t } = useTranslation('filters')

  if (field.type === 'text' && isEqualsFilter(filter) && filter.equals !== '') {
    return `${field.name}: ${String(filter.equals)}`
  } else if (field.type === 'text' && isNotEqualsFilter(filter) && filter.notEquals !== '') {
    return `${field.name}: ${t('not')} ${String(filter.notEquals)}`
  } else if (['boolean', 'date', 'number', 'text', 'vector'].includes(field.type) && isHasErrorFilter(filter)) {
    return `${field.name}: ${filter.hasError ? t('has error') : t('has no error')}`
  } else if (['text', 'number', 'date', 'file'].includes(field.type) && isIsEmptyFilter(filter)) {
    return `${field.name}: ${filter.isEmpty ? t('is empty') : t('is not empty')}`
  } else if (['boolean', 'date', 'number', 'text', 'vector'].includes(field.type) && isIsPendingFilter(filter)) {
    return `${field.name}: ${filter.isPending ? t('is pending') : t('is not pending')}`
  } else if (field.type === 'number' && isEqualsFilter(filter) && filter.equals !== '') {
    return `${field.name} = ${String(filter.equals)}`
  } else if (field.type === 'number' && isNotEqualsFilter(filter) && filter.notEquals !== '') {
    return `${field.name} ≠ ${String(filter.notEquals)}`
  } else if (field.type === 'number' && isGreaterThanFilter(filter) && filter.greaterThan !== '') {
    return `${field.name} > ${filter.greaterThan}`
  } else if (field.type === 'number' && isLessThanFilter(filter) && filter.lessThan !== '') {
    return `${field.name} < ${filter.lessThan}`
  } else if (field.type === 'number' && isGreaterThanOrEqualsFilter(filter) && filter.greaterThanOrEquals !== '') {
    return `${field.name} ≥ ${filter.greaterThanOrEquals}`
  } else if (field.type === 'number' && isLessThanOrEqualsFilter(filter) && filter.lessThanOrEquals !== '') {
    return `${field.name} ≤ ${filter.lessThanOrEquals}`
  } else if (field.type === 'boolean' && isEqualsFilter(filter)) {
    return `${field.name}: ${filter.equals ? t('checked') : t('unchecked')}`
  } else if (field.type === 'boolean' && isNotEqualsFilter(filter)) {
    return `${field.name}: ${t('not')} ${filter.notEquals ? t('checked') : t('unchecked')}`
  } else if (field.type === 'date' && isEqualsFilter(filter) && filter.equals !== '' && (typeof filter.equals === 'string')) {
    return `${field.name}: ${getDateLabel(filter.equals)}`
  } else if (field.type === 'date' && isLessThanFilter(filter) && filter.lessThan !== '' && (typeof filter.lessThan === 'string')) {
    return `${field.name}: ${t('before')} ${getDateLabel(filter.lessThan)}`
  } else if (field.type === 'date' && isGreaterThanFilter(filter) && filter.greaterThan !== '' && (typeof filter.greaterThan === 'string')) {
    return `${field.name}: ${t('after')} ${getDateLabel(filter.greaterThan)}`
  } else if (field.type === 'date' && isLessThanOrEqualsFilter(filter) && filter.lessThanOrEquals !== '' && (typeof filter.lessThanOrEquals === 'string')) {
    return `${field.name}: ${t('on or before')} ${getDateLabel(filter.lessThanOrEquals)}`
  } else if (field.type === 'date' && isGreaterThanOrEqualsFilter(filter) && filter.greaterThanOrEquals !== '' && (typeof filter.greaterThanOrEquals === 'string')) {
    return `${field.name}: ${t('on or after')} ${getDateLabel(filter.greaterThanOrEquals)}`
  } else if (field.type === 'select' && isEqualsFilter(filter) && filter.equals !== '') {
    const optionLabelById = Object.fromEntries(
      ((field.data as SelectFieldData).select?.options ?? []).map((option: SelectFieldDataOption) => [option.id, option.label])
    )

    return `${field.name}: ${(Array.isArray(filter.equals) ? filter.equals : []).map((id: string) => optionLabelById[id]).join(', ')}`
  } else if (field.type === 'select' && isNotEqualsFilter(filter) && filter.notEquals !== '') {
    const optionLabelById = Object.fromEntries(
      ((field.data as SelectFieldData).select?.options ?? []).map((option: SelectFieldDataOption) => [option.id, option.label])
    )

    return `${field.name}: ${t('not')} ${(Array.isArray(filter.notEquals) ? filter.notEquals : []).map((id: string) => optionLabelById[id]).join(', ')}`
  }

  return field.name
}

function getDateLabel (date: string) {
  const dateWithTz = `${date}T00:00:00.000`
  const parsedDate = new Date(dateWithTz)
  if (isThisYear(parsedDate)) {
    return format(parsedDate, 'MMM d', { locale: i18n.language === 'en' ? enUS : ptBR })
  }

  return format(parsedDate, 'MMM d, yyyy', { locale: ptBR })
}

import { ChatField, ChatFieldData, UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmDeleteDialog } from '../../../../../../components'
import { useCogfy } from '../../../../../../hooks'
import { CollectionStateField } from '../../../../../../lib'
import { WhatsAppWizard } from './WhatsAppWizard'

export type WhatsAppParametersProps = {
  field: ChatField
  onChange: (fieldId: UUID, data: ChatFieldData) => void
  onClear: (field: CollectionStateField) => void
}

export function WhatsAppParameters (props: WhatsAppParametersProps) {
  const { field, onClear } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const cogfy = useCogfy()

  const whatsApp = field.data?.chat?.whatsApp

  const clearWhatsApp = useMutation({
    mutationFn: () => cogfy.fields.clearWhatsApp(field.id),
    onSuccess: () => {
      const oldData = field.data
      const newField = {
        ...field,
        data: {
          ...oldData,
          chat: {
            ...oldData?.chat,
            whatsApp: undefined
          }
        }
      }

      onClear(newField)
      setOpen(false)
    }
  })

  const handleConfirmation = (data: NonNullable<ChatFieldData['chat']>['whatsApp']) => {
    props.onChange(
      field.id,
      {
        chat: {
          ...field.data?.chat,
          whatsApp: data
        }
      }
    )
  }

  return (
    <>
      {whatsApp && (
        <>
          <div className="card card-compact bg-[#65D072]">
            <div className="card-body">
              <div>
                <label className="label-text">{t('WhatsApp Business Account ID')}</label>
                <div className="text-sm">{whatsApp.wabaId}</div>
              </div>
              <div>
                <label className="label-text">{t('editFieldDrawer:Phone number')}</label>
                <div className="text-sm">{`${whatsApp.phoneNumberName}: ${whatsApp.displayPhoneNumber}`}</div>
              </div>
              <div className="card-actions justify-end">
                {/* Disable clear button while there is old whatsapp field */}
                {/* <Button
                  size="xs"
                  disabled={clearWhatsApp.isPending}
                  onClick={() => setOpen(true)}
                >
                  Clear
                </Button> */}
              </div>
            </div>
          </div>

          <ConfirmDeleteDialog
            open={open}
            title={t('Clear WhatsApp phone number')}
            message={t('Are you sure you want to clear the WhatsApp phone number?')}
            onClose={() => setOpen(false)}
            onConfirm={() => {
              clearWhatsApp.mutate()
            }}
          />
        </>
      )}
      {!whatsApp && (
        <WhatsAppWizard
          fieldId={field.id}
          onConfirm={handleConfirmation}
        />
      )}
    </>
  )
}

export const connectionDefinitionByProvider = {
    aws: {
        label: 'AWS',
        values: [
            { name: 'access_key_id', encrypted: true },
            { name: 'secret_access_key', encrypted: true },
            { name: 'region', encrypted: false }
        ]
    },
    http: {
        label: 'HTTP',
        values: [
            { name: 'base_url', encrypted: false },
            { name: 'header_name', encrypted: false },
            { name: 'header_value', encrypted: true }
        ]
    },
    openai: {
        label: 'OpenAI',
        values: [
            { name: 'api_key', encrypted: true }
        ]
    },
    sendgrid: {
        label: 'Twilio SendGrid',
        values: [
            { name: 'api_key', encrypted: true }
        ]
    },
    unstructured: {
        label: 'Unstructured',
        values: [
            { name: 'api_key', encrypted: true },
            { name: 'api_url', encrypted: false }
        ]
    },
    whatsapp: {
        label: 'WhatsApp',
        values: [
            { name: 'wa_business_account_id', encrypted: true },
            { name: 'cloud_api_access_token', encrypted: true }
        ]
    }
};

import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Input, Select, Toggle } from '../../../components'
import { useEffect, useState } from 'react'
import { useSelectedFieldToUpdate } from '../../../lib'
import { FileField } from '@indigohive/cogfy-types'
import { FieldConfigurationsProps } from '../FieldConfigurations'
import { InfoIcon } from 'lucide-react'

const MIN_FILE_SIZE_IN_MB = 1
const MAX_FILE_SIZE_IN_MB = 20

const fileTypeOptions = [
  { value: '*', label: 'All types' },
  { value: 'application/pdf', label: '.pdf' },
  { value: 'application/msword text/plain', label: '.doc' },
  { value: 'application/vnd.ms-excel', label: '.xls' },
  { value: 'application/vnd.ms-powerpoint', label: '.ppt' },
  { value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', label: '.docx' },
  { value: 'application/xml', label: '.xml' },
  { value: 'application/zip', label: '.zip' },
  { value: 'image/png', label: '.png' },
  { value: 'image/jpeg', label: '.jpeg' },
  { value: 'image/jpg', label: '.jpg' },
  { value: 'text/csv', label: '.csv' },
  { value: 'text/html', label: '.html' },
  { value: 'text/xml', label: '.xml' }
]

const schema = yup.number().integer().min(MIN_FILE_SIZE_IN_MB).max(MAX_FILE_SIZE_IN_MB)

export function FileConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props

  const { t } = useTranslation()
  const field = useSelectedFieldToUpdate(state) as FileField
  const [fileSize, setFileSize] = useState(field.data?.file?.size ? ((field.data?.file?.size) / (1024 * 1024)) : MAX_FILE_SIZE_IN_MB)
  const [fileType, setFileType] = useState(field.data?.file?.types ? field.data.file.types.join(' ') : '*')
  const [isPublicFileOnS3, setIsPublicFileOnS3] = useState(field.data?.file?.public ?? false)

  const isValid = schema.isValidSync(fileSize)

  useEffect(() => {
    const fileSizeInMb = field.data?.file?.size ? ((field.data?.file?.size) / (1024 * 1024)) : MAX_FILE_SIZE_IN_MB

    setFileSize(fileSizeInMb)
    setFileType(field?.data?.file?.types?.[0] ?? '*')
  }, [field.data])

  return (
    <>
      <Input
        label={t('editFieldDrawer:Maximum files size (MB)')}
        type="number"
        size="sm"
        onChange={(event) => setFileSize(Number(event.target.value))}
        onBlur={_ => {
          if (isValid) {
            controller.onUpdateFieldData(field.id, { file: { ...field.data?.file, size: fileSize * 1024 * 1024 } })
          }
        }}
        value={fileSize}
      />
      {!isValid && (
        <span className="text-xs text-error pt-1">{t('editFieldDrawer:Must be between 1 and 20 MB')}</span>
      )}
      <Select
        label={t('editFieldDrawer:File type')}
        size="sm"
        value={fileType}
        onChange={(event) => setFileType(event.target.value)}
        options={fileTypeOptions}
        onBlur={_ => controller.onUpdateFieldData(field.id, { file: { ...field.data?.file, types: [fileType] } })}
      />
      <span className="text-xs pb-1">{t('editFieldDrawer:The collection that will be referenced in this field')}</span>
      <Toggle
        color='primary'
        label={
          <>
            {t('editFieldDrawer:Public file')}
            <div className="tooltip tooltip-bottom" data-tip={t('editFieldDrawer:The file is publicly accessible')}>
              <InfoIcon size={20} />
            </div>
          </>
          }
        onChange={_ => setIsPublicFileOnS3(!isPublicFileOnS3)}
        checked={isPublicFileOnS3}
        onBlur={_ => controller.onUpdateFieldData(field.id, { file: { ...field.data?.file, public: isPublicFileOnS3 } })}
      />
    </>
  )
}

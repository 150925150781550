import { FieldOperationConfigNumberInput, OperationInputType, OperationSchemaInput } from '@indigohive/cogfy-types'
import { CollectionState, useSelectedFieldToUpdate } from '../../../../../lib'
import { CollectionPageController } from '../../../collection-page-controller'

export type FieldOperationNumberInputProps = {
  controller: CollectionPageController
  state: CollectionState
  inputName: string
  input: OperationSchemaInput<OperationInputType>
}

export function FieldOperationNumberInput (props: FieldOperationNumberInputProps) {
  const { controller, state, inputName, input } = props
  const selectedField = useSelectedFieldToUpdate(state)

  return (
    <input
      type="number"
      className="input input-sm input-bordered w-full"
      step={input.input?.step}
      min={input.input?.min}
      max={input.input?.max}
      value={
        (selectedField?.operationConfig?.[inputName] as FieldOperationConfigNumberInput | undefined)?.number.value ?? Number(input.default) ?? 0
      }
      onChange={event => {
        const newValue = parseFloat(event.target.value)
        controller.onOperationChange(
          selectedField!.id,
          selectedField?.operation ?? null,
          selectedField!.recalculateStrategy,
          {
            ...selectedField?.operationConfig,
            [inputName]: { type: 'number', number: { value: newValue } }
          }
        )
      }}
    />
  )
}

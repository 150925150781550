import { ChatField, ProviderName, UUID } from '@indigohive/cogfy-types'
import { useEffect, useState } from 'react'
import { MentionsValue } from '../../../../components'
import { useSelectedFieldToUpdate } from '../../../../lib'
import { FieldConfigurationsProps } from '../../FieldConfigurations'
import { ChatConfigurationsBase } from './components'
import { ChatMiddlewareList } from './components/ChatMiddlewareList'
import { ChatConnections } from './components/ChatConnections'

const defaultCountDocuments = 5
const defaultInitialMessage = 'Hello! How may I help you?'
const defaultPrompt = "You're a helpful assistant."
const defaultTemperature = 0.7
const defaultThreshold = 0.45

export type ChatConfigurationsValues = {
  model: string
  initialMessage: MentionsValue
  collectionIds: UUID[]
  threshold: number
  temperature: number
  countDocuments: number
  promptTemplate: MentionsValue
}

export type ModelOption = {
  value: string
  label: string
  disabled: boolean
  provider?: ProviderName
}

export function ChatConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props

  const selectedField = useSelectedFieldToUpdate(state) as ChatField
  const collectionId = state.id
  const fieldData = selectedField.data?.chat

  const [configValues, setConfigValues] = useState<ChatConfigurationsValues>({
    model: fieldData?.model ?? '',
    initialMessage: {
      parts: typeof fieldData?.initialMessage === 'string'
        ? [fieldData?.initialMessage]
        : fieldData?.initialMessage?.template.strings ?? [defaultInitialMessage],
      options: typeof fieldData?.initialMessage === 'string'
        ? []
        : fieldData?.initialMessage?.template.args.map(arg => ({
          id: arg.fieldId,
          name: state.fields?.find(field => field.id === arg.fieldId)?.name ?? '???'
        })) ?? []
    },
    collectionIds: fieldData?.collectionIds ?? [],
    threshold: fieldData?.threshold ?? defaultThreshold,
    temperature: fieldData?.temperature ?? defaultTemperature,
    countDocuments: fieldData?.countDocuments ?? defaultCountDocuments,
    promptTemplate: {
      parts: typeof fieldData?.prompt === 'string'
        ? [fieldData?.prompt]
        : fieldData?.prompt?.template.strings ?? [defaultPrompt],
      options: typeof fieldData?.prompt === 'string'
        ? []
        : fieldData?.prompt?.template.args.map(arg => ({
          id: arg.fieldId,
          name: state.fields?.find(field => field.id === arg.fieldId)?.name ?? '???'
        })) ?? []
    }
  })

  const onConfigValuesChange = (prop: keyof ChatConfigurationsValues, value: any) => {
    setConfigValues(prevValues => ({
      ...prevValues,
      [prop]: value
    }))
  }

  useEffect(() => {
    setConfigValues({
      model: selectedField.data?.chat?.model ?? '',
      initialMessage: {
        parts: typeof selectedField.data?.chat?.initialMessage === 'string'
          ? [selectedField.data?.chat?.initialMessage]
          : selectedField.data?.chat?.initialMessage?.template.strings ?? [defaultInitialMessage],
        options: typeof selectedField.data?.chat?.initialMessage === 'string'
          ? []
          : selectedField.data?.chat?.initialMessage?.template.args.map(arg => ({
            id: arg.fieldId,
            name: state.fields?.find(field => field.id === arg.fieldId)?.name ?? '???'
          })) ?? []
      },
      collectionIds: selectedField.data?.chat?.collectionIds ?? [],
      threshold: selectedField.data?.chat?.threshold ?? defaultThreshold,
      temperature: selectedField.data?.chat?.temperature ?? defaultTemperature,
      countDocuments: selectedField.data?.chat?.countDocuments ?? defaultCountDocuments,
      promptTemplate: {
        parts: typeof selectedField.data?.chat?.prompt === 'string'
          ? [selectedField.data?.chat?.prompt]
          : selectedField.data?.chat?.prompt?.template.strings ?? [defaultPrompt],
        options: typeof selectedField.data?.chat?.prompt === 'string'
          ? []
          : selectedField.data?.chat?.prompt?.template.args.map(arg => ({
            id: arg.fieldId,
            name: state.fields?.find(field => field.id === arg.fieldId)?.name ?? '???'
          })) ?? []
      }
    })
  }, [selectedField.data])

  return (
    <>
      <div className="divider my-0" />

      <ChatConfigurationsBase
        controller={controller}
        state={state}
        values={configValues}
        onChange={onConfigValuesChange}
      />

      <ChatMiddlewareList
        fieldId={selectedField.id}
        collectionId={collectionId}
        fields={state.fields?.map(field => ({
          id: field.id,
          name: field.name,
          type: field.type
        }))}
      />

      <div className="divider" />

      <ChatConnections
        field={selectedField}
        state={state}
        controller={controller}
      />
    </>
  )
}

import * as yup from 'yup'
import { useState } from 'react'
import { Input } from '../../../Input'
import { ChatMiddlewareDataFormProps } from '../../ChatMiddlewareDataFormProps'
import { Select } from '../../../Select'
import { ToolFormHttpRequest } from './_ToolFormHttpRequest'
import { ToolFormSearchCogfyRecords } from './_ToolFormSearchCogfyRecords'
import { ToolFormCreateCogfyRecord } from './_ToolFormCreateCogfyRecord'
import { ToolFormUpdateCogfyRecord } from './_ToolFormUpdateCogfyRecord'
import { useTranslation } from 'react-i18next'

const toolOptions = [
  { label: 'Select a tool type', value: '', disabled: true },
  { label: 'Create Cogfy record', value: 'createCogfyRecord' },
  { label: 'Get date now', value: 'getDate' },
  { label: 'HTTP', value: 'httpRequest' },
  { label: 'Search Cogfy records', value: 'searchCogfyRecords' },
  { label: 'Update Cogfy record', value: 'updateCogfyRecord' }
]

export const nameValidation = yup
  .string()
  .matches(/^[a-zA-Z0-9_-]+$/)

const TOOL_FORMS_BY_TYPE: Record<string, typeof ToolFormCreateCogfyRecord> = {
  createCogfyRecord: ToolFormCreateCogfyRecord,
  httpRequest: ToolFormHttpRequest,
  searchCogfyRecords: ToolFormSearchCogfyRecords,
  updateCogfyRecord: ToolFormUpdateCogfyRecord
}

export function ToolForm (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props

  const { t } = useTranslation()
  const [name, setName] = useState(data.name ?? '')
  const [description, setDescription] = useState(data.description ?? '')
  const [type, setType] = useState(data.method?.name ?? '')

  const ToolFormComponent = TOOL_FORMS_BY_TYPE[type]

  const isNameValid = nameValidation.isValidSync(name)

  return (
    <div className="flex flex-col gap-2">
      <Input
        label={t('editFieldDrawer:Tool name')}
        size="sm"
        value={name}
        onChange={event => {
          const newName = event.target.value
          setName(newName)
          if (nameValidation.isValidSync(newName)) onChange({ ...props.data, name: newName })
        }}
        error={!isNameValid && name.length > 0}
        helperText={!isNameValid && name.length > 0 ? t('editFieldDrawer:Name must contain only letters, numbers, _ or -') : ''}
      />
      <Input
        label={t('editFieldDrawer:Tool description')}
        size="sm"
        value={description}
        onChange={event => {
          setDescription(event.target.value)
          onChange({ ...props.data, description: event.target.value })
        }}
      />
      <Select
        label={t('editFieldDrawer:Tool method')}
        size="sm"
        options={toolOptions.map(option => ({ ...option, label: t(`editFieldDrawer:${option.label}`) }))}
        value={type}
        onChange={event => {
          setType(event.target.value)
          onChange({ ...props.data, method: { name: event.target.value } })
        }}
      />

      {ToolFormComponent && <ToolFormComponent {...props} />}
    </div>
  )
}

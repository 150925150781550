import { useQuery } from '@tanstack/react-query'
import { GetWorkspaceUserContactsResultData } from '@indigohive/cogfy-types/endpoints/getWorkspaceUserContacts'
import { useTranslation } from 'react-i18next'
import { TrashIcon } from 'lucide-react'
import { useState } from 'react'
import { CreateWorkspaceUserContactDialog } from './CreateWorkspaceUserContactDialog'
import { DeleteWorkspaceUserContactDialog } from './DeleteWorkspaceUserContactDialog'
import { useCogfy } from '../../../hooks'
import { Button, Card } from '../../../components'

export function WorkspaceUserContactsTable () {
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const [contactToDelete, setContactToDelete] = useState<GetWorkspaceUserContactsResultData | null>(null)
  const getUserContacts = useQuery({
    queryKey: ['getWorkspaceUserContacts', {}] as const,
    queryFn: ({ queryKey, signal }) => cogfy.getWorkspaceUserContacts(queryKey[1], { signal })
  })

  return (
    <div className="my-8">
      <div className="flex">
        <h2 className="grow text-lg font-bold">
          {t('Contacts')}
        </h2>
        <CreateWorkspaceUserContactDialog
          onSuccess={() => { getUserContacts.refetch() }}
        />
      </div>
      <DeleteWorkspaceUserContactDialog
        open={contactToDelete !== null}
        onClose={() => setContactToDelete(null)}
        userContactId={contactToDelete?.id}
        onSuccess={() => {
          getUserContacts.refetch()
          setContactToDelete(null)
        }}
      />
      <Card className="mt-4">
        <table className="table">
          <thead>
            <tr>
              <th>{t('User')}</th>
              <th>{t('Type')}</th>
              <th>{t('Value')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getUserContacts.data?.data.map(row => (
              <tr key={row.id}>
                <td>
                  {row.user === null && (
                    <span className="opacity-40 italic">
                      {t('WorkspaceAssistantPage:User not set')}
                    </span>
                  )}
                  {row.user?.name}
                </td>
                <td>{row.type === 'phone' && 'Phone'}</td>
                <td>{row.value}</td>
                <td>
                  <Button
                    size="xs"
                    onClick={() => setContactToDelete(row)}
                  >
                    <TrashIcon size={16} />
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  )
}

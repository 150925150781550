import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useCogfy, useTitle, useWorkspace } from '../../hooks'
import { Button, Container, SettingsPagesHeader } from '../../components'
import { ConnectionSection } from './components'
import { GetConnectionsResultData, ProviderName } from '@indigohive/cogfy-types'
import { WandSparkles } from 'lucide-react'

const PROVIDERS: { name: ProviderName, type: 'AI' | 'services' }[] = [
  { name: 'aws', type: 'AI' },
  { name: 'http', type: 'services' },
  { name: 'openai', type: 'AI' },
  { name: 'sendgrid', type: 'services' },
  { name: 'whatsapp', type: 'services' }
]

export function WorkspaceConnectionsPage () {
  const params = useParams<{ workspace: string }>()
  const cogfy = useCogfy()
  const getWorkspace = useWorkspace(params.workspace!)
  const { t } = useTranslation()
  const location = useLocation()
  const [selectedPage, setSelectedPage] = useState<'AI' | 'services'>('AI')

  const getConnections = useQuery({
    queryKey: ['getConnections'],
    queryFn: ({ signal }) => cogfy.connections.getList({}, signal)
  })

  const connectionsByProvider = getConnections.data?.data.reduce<Partial<Record<ProviderName, GetConnectionsResultData[]>>>(
    (acc, connection) => {
      if (!acc[connection.provider]) {
        acc[connection.provider] = []
      }

      acc[connection.provider]?.push(connection)

      return acc
    }, {}) ?? {}

  useTitle({
    title: t('Connections'),
    loading: getWorkspace.isLoading,
    error: getWorkspace.isError
  })

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash)

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location.hash])

  return (
    <>
      <SettingsPagesHeader
        title={t('Connections')}
        description={t('WorkspaceConnectionsPage:Configure and customize connections with external services')}
      >
        <WandSparkles size={46} />
      </SettingsPagesHeader>
      <Container className='pt-6'>
        <div className='pl-14 flex gap-2'>
          <Button
            ghost
            className={`${selectedPage === 'AI' && 'bg-[#D9E7FE] text-[#2477D1]'}`}
            onClick={() => setSelectedPage('AI')}
          >
            <span className=''>{t('WorkspaceConnectionsPage:AI Providers')}</span>
          </Button>
          <Button
            ghost
            className={`${selectedPage === 'services' && 'bg-[#D9E7FE] text-[#2477D1]'}`}
            onClick={() => setSelectedPage('services')}
          >
            <span>{t('WorkspaceConnectionsPage:Service Providers')}</span>
          </Button>
        </div>
        <div className="flex flex-col items-center space-y-12 my-12">
          {!getConnections.data && !getConnections.isLoading && (
            <span className='self-start pl-16'>{t('Error when searching connections')}</span>
          )}
          {getConnections.isLoading && <progress className="progress w-1/3" />}
          {getConnections.data &&
            PROVIDERS.filter(provider => provider.type === selectedPage).map(provider => (
              <ConnectionSection
                key={provider.name}
                provider={provider.name}
                connections={connectionsByProvider[provider.name] ?? []}
                onRefetch={() => { getConnections.refetch() }}
              />
            ))
          }

        </div>
      </Container>
    </>
  )
}

import { FieldType, UUID } from '@indigohive/cogfy-types'
import { CreateChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/createChatMiddleware'
import { DeleteChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/deleteChatMiddleware'
import { GetChatMiddlewaresResultData } from '@indigohive/cogfy-types/endpoints/getChatMiddlewares'
import { ReorderChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/reorderChatMiddleware'
import { UpdateChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/updateChatMiddleware'
import { useMutation } from '@tanstack/react-query'
import { PlusIcon } from 'lucide-react'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useChatMiddlewares, useCogfy, useCollections } from '../../../../../../hooks'
import { Button } from '../../../../../../components'
import { AddChatMiddlewareContextMenu } from './AddChatMiddlewareContextMenu'
import { ChatMiddlewareContextMenu } from './ChatMiddlewareContextMenu'
import { EditChatMiddlewareForm } from './EditChatMiddlewareForm'
import { TreeView } from '../../../../../../components/TreeView'
import { ChatMiddlewareIcon } from '../../../../../../components/ChatMiddlewareIcon'
import clsx from 'clsx'
import { TFunction } from 'i18next'
import { DuplicateChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/duplicateChatMiddleware'

export type ChatMiddlewareListProps = {
  fieldId: UUID
  collectionId: UUID
  fields?: {
    id: string
    name: string
    type: FieldType
  }[]
}

type ContextMenuMiddleware = {
  element: HTMLElement
  middleware: GetChatMiddlewaresResultData
}

type ChatMiddlewareTreeItem = Omit<GetChatMiddlewaresResultData, 'children'> & {
  label: ReactNode
  icon?: ReactNode
  canDropOver: boolean
  disabled?: boolean
  children: ChatMiddlewareTreeItem[] | undefined
  middleware: GetChatMiddlewaresResultData
}

function map (
  middleware: GetChatMiddlewaresResultData,
  ancestorDisabled: boolean,
  t: TFunction<'translation', undefined>
): ChatMiddlewareTreeItem {
  const disabled = ancestorDisabled || !middleware.enabled

  return {
    ...middleware,
    label: (
      <div className="flex items-center gap-2">
        <div className="grow">
          {middleware.name}
          {!middleware.enabled && <> ({t('Disabled').toLowerCase()})</>}
        </div>
        <span className={clsx('text-xs', 'opacity-40', middleware.type !== 'group' && 'mr-4')}>
          {middleware.type}
        </span>
      </div>
    ),
    icon: <ChatMiddlewareIcon type={middleware.type} size={16} />,
    children: middleware.type !== 'group'
      ? undefined
      : middleware.children?.map(child => map(child, ancestorDisabled || !middleware.enabled, t)) ?? [],
    canDropOver: middleware.type === 'group',
    middleware,
    disabled
  }
}

export function ChatMiddlewareList (props: ChatMiddlewareListProps) {
  const { fieldId, collectionId } = props

  const { t } = useTranslation()
  const client = useCogfy()
  const [addMiddlewareAnchorEl, setAddMiddlewareAnchorEl] = useState<HTMLElement | null>(null)
  const [contextMenuMiddleware, setContextMenuMiddleware] = useState<ContextMenuMiddleware | null>(null)
  const [selectedMiddleware, setSelectedMiddleware] = useState<GetChatMiddlewaresResultData | null>(null)
  const getCollections = useCollections()
  const getMiddlewares = useChatMiddlewares(collectionId, fieldId)
  const createMiddleware = useMutation({
    mutationFn: (data: CreateChatMiddlewareCommand) => client.createChatMiddleware(data),
    onSuccess: () => getMiddlewares.refetch()
  })
  const deleteMiddleware = useMutation({
    mutationFn: (data: DeleteChatMiddlewareCommand) => client.deleteChatMiddleware(data),
    onSuccess: () => getMiddlewares.refetch()
  })
  const duplicateMiddleware = useMutation({
    mutationFn: (data: DuplicateChatMiddlewareCommand) => client.duplicateChatMiddleware(data),
    onSuccess: () => getMiddlewares.refetch()
  })
  const reorderMiddleware = useMutation({
    mutationFn: (data: ReorderChatMiddlewareCommand) => client.reorderChatMiddleware(data),
    onSuccess: () => getMiddlewares.refetch()
  })
  const updateMiddleware = useMutation({
    mutationFn: (data: UpdateChatMiddlewareCommand) => client.updateChatMiddleware(data),
    onSuccess: () => getMiddlewares.refetch()
  })

  return (
    <div>
      <div className="flex items-center">
        <h3 className="text-sm font-bold grow">
          Middlewares
        </h3>
        <Button
          size="sm"
          ghost
          square
          onClick={event => setAddMiddlewareAnchorEl(event.currentTarget)}
        >
          <PlusIcon size={16} />
        </Button>
      </div>
      <TreeView
        items={getMiddlewares.data?.data?.map(middleware => map(middleware, false, t)) ?? []}
        placeholder={{
          label: (
            <span className="opacity-40">
              {t('editFieldDrawer:No middlewares configured')}
            </span>
          )
        }}
        onItemClick={item => {
          setSelectedMiddleware(item.middleware)
        }}
        onContextMenu={(event, item) => {
          setContextMenuMiddleware({ element: event.currentTarget, middleware: item.middleware })
          event.stopPropagation()
        }}
        onDrop={(middleware, over, position) => {
          const chatMiddlewareId = middleware.id
          if (position === 'bottom') {
            reorderMiddleware.mutate({ chatMiddlewareId, afterId: over.id })
          } else if (position === 'middle') {
            reorderMiddleware.mutate({ chatMiddlewareId, parentId: over.id })
          } else {
            reorderMiddleware.mutate({ chatMiddlewareId, beforeId: over.id })
          }
        }}
      />
      <AddChatMiddlewareContextMenu
        anchorEl={addMiddlewareAnchorEl}
        onAddMiddlewareClick={type => {
          createMiddleware.mutate({ fieldId, type })
          setAddMiddlewareAnchorEl(null)
        }}
        onClose={() => setAddMiddlewareAnchorEl(null)}
      />
      <ChatMiddlewareContextMenu
        anchorEl={contextMenuMiddleware?.element}
        middleware={contextMenuMiddleware?.middleware}
        onClose={() => setContextMenuMiddleware(null)}
        onDeleteClick={middleware => {
          setSelectedMiddleware(null)
          deleteMiddleware.mutate({ chatMiddlewareId: middleware.id })
        }}
        onDuplicateClick={middleware => {
          duplicateMiddleware.mutate({ middlewareId: middleware.id })
        }}
        onDisableClick={middleware => {
          updateMiddleware.mutate({ chatMiddlewareId: middleware.id, enabled: !middleware.enabled })
        }}
        onEditClick={middleware => {
          setSelectedMiddleware(middleware)
        }}
      />

      <div className="divider" />
      {selectedMiddleware && (
        <EditChatMiddlewareForm
          key={selectedMiddleware.id}
          fieldId={fieldId}
          fields={props.fields}
          collections={getCollections.data?.data ?? []}
          middleware={selectedMiddleware}
        />
      )}
      {!selectedMiddleware && (
        <div className="text-sm opacity-40">
          {t('editFieldDrawer:No middleware selected')}
        </div>
      )}
    </div>
  )
}

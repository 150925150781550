import { GetChatMessagesPageResultData } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { getHashOfString, normalizeHash } from '../../../helpers'
import { WhatsAppContent } from './WhatsAppContent'
import { WhatsAppFooter } from './WhatsAppFooter'
import { WhatsAppTitle } from './WhatsAppTitle'
import { useTranslation } from 'react-i18next'

export type WhatsAppBubbleProps = {
  senderName: string
  displayPhoneNumber?: string
  message: GetChatMessagesPageResultData
  avatarImageSrc?: string
  hideAvatar?: boolean
  fromAuthenticatedUser: boolean
}

export function WhatsAppBubble (props: WhatsAppBubbleProps) {
  const { senderName, displayPhoneNumber, message, avatarImageSrc, hideAvatar, fromAuthenticatedUser } = props
  const { t } = useTranslation()

  const displayColor = displayPhoneNumber
    ? getDisplayColor(displayPhoneNumber)
    : undefined

  return (
    <div
      className={clsx(
        'chat',
        !fromAuthenticatedUser && 'chat-start',
        fromAuthenticatedUser && 'chat-end'
      )}
    >
      {/* Avatar */}
      <div
        className={clsx('chat-image avatar', hideAvatar && 'invisible', !avatarImageSrc && 'placeholder')}
        title={senderName || t('Unknown user')}
      >
        <div
          className={clsx('w-10 rounded-full text-white')}
          style={{ backgroundColor: avatarImageSrc ? undefined : displayColor }}
        >
          {avatarImageSrc && <img src={avatarImageSrc} />}
          {!avatarImageSrc && (
            <>
              {senderName ? senderName[0].toLocaleUpperCase() : '?'}
            </>
          )}
        </div>
      </div>

      {/* Bubble */}
      <div
        className={clsx(
          'flex flex-col',
          'py-1 px-2',
          'chat-bubble text-black !rounded-btn',
          hideAvatar && 'before:content-none !rounded-btn',
          !fromAuthenticatedUser && 'bg-white',
          fromAuthenticatedUser && 'bg-[#d9fdd3]',
          !fromAuthenticatedUser && !hideAvatar && '!rounded-es-none',
          fromAuthenticatedUser && !hideAvatar && '!rounded-ee-none'
        )}
      >
        <WhatsAppTitle name={senderName} displayPhoneNumber={displayPhoneNumber} displayColor={displayColor} />
        {/* TODO: Add subtitle component when implements context info */}
        {/* TODO: Add context component when implements context info */}
        <div className="inline-flex gap-2">
          <WhatsAppContent
            className="grow pb-1"
            content={message.content}
            contentType={message.contentType}
            contentData={message.contentData}
            files={message.files}
            fromAuthenticatedUser={fromAuthenticatedUser}
          />
          <WhatsAppFooter
            sendDate={message.sendDate}
            messageStatus={message.status}
            hideStatusIcon={!fromAuthenticatedUser}
          />
        </div>
      </div>
    </div>
  )
}

function getDisplayColor (value: string) {
  const hash = getHashOfString(value)
  const h = normalizeHash(hash, 0, 360)
  const s = normalizeHash(hash, 50, 75)
  const l = normalizeHash(hash, 25, 60)

  return `hsl(${h}, ${s}%, ${l}%)`
}

import type { FieldOperationConfigTextInput, RecalculateStrategy } from '@indigohive/cogfy-types'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from '../../../../components'
import { CollectionState, useFields, useSelectedFieldToUpdate } from '../../../../lib'
import { useOperationSchemas } from '../../../../hooks'
import { CollectionPageController } from '../../collection-page-controller'
import { FieldOperationFieldsInput } from './subcomponents/FieldOperationFieldsInput'
import { FieldOperationNumberInput } from './subcomponents/FieldOperationNumberInput'
import { FieldOperationTextInput } from './subcomponents/FieldOperationTextInput'
import { InfoIcon } from 'lucide-react'
import { FieldOperationCollectionInput } from './subcomponents/FieldOperationCollectionInput'
import { FieldOperationMultipleTextInput } from './subcomponents/FieldOperationMultipleTextInput'
import { FieldOperationBooleanInput } from './subcomponents/FieldOperationBooleanInput'

const recalculateStrategyOptions: { label: string, value: RecalculateStrategy }[] = [
  { label: 'Always recalculate', value: 'always' },
  { label: 'Only when cell is empty', value: 'empty_only' },
  { label: 'Only manually', value: 'manually' }
]

export type OperationFormProps = {
  controller: CollectionPageController
  state: CollectionState
}

export function OperationForm (props: OperationFormProps) {
  const { controller, state } = props
  const { t } = useTranslation()
  const fields = useFields(state)
  const selectedField = useSelectedFieldToUpdate(state)
  const getOperations = useOperationSchemas(selectedField)
  const operations = getOperations.data?.data
  const operation = operations?.find(op => op.name === selectedField?.operation) ?? null
  const options = useMemo(() => {
    const result: { label: ReactNode, value: string }[] = [
      { label: t('operationForm:Select an operation'), value: '' }
    ]

    if (operations) {
      result.push(...operations?.map(op => ({ label: op.name, value: op.name })))
    }

    return result
  }, [operations])

  return (
    <div className="flex flex-col gap-4 text-sm">
      {options.length > 1 && (
        <div>
          <div className="py-1">
            {t('Operation')}
          </div>
          <Select
            size="sm"
            value={operation?.name ?? ''}
            onChange={event => {
              controller.onOperationChange(
                selectedField!.id,
                event.target.value || null,
                selectedField!.recalculateStrategy,
                null
              )
            }}
            options={options}
          />
        </div>
      )}
      {operation && (
        <div>
          <div className="flex flex-row items-center gap-1 py-1">
            <span>{t('operationForm:Recalculate operation method')}</span>
            <span
              className="tooltip before:w-[200px] before:content-[attr(data-tip)]"
              data-tip={t('operationForm:How cell recalculates when field source data changes')}
            >
              <InfoIcon size={16} className="text-slate-400" />
            </span>
          </div>
          <Select
            size="sm"
            value={selectedField?.recalculateStrategy ?? recalculateStrategyOptions[0].value}
            onChange={event => {
              controller.onOperationChange(
                selectedField!.id,
                selectedField!.operation ?? null,
                event.target.value as RecalculateStrategy,
                selectedField!.operationConfig ?? null
              )
            }}
            options={recalculateStrategyOptions.map(({ label, value }) => ({ label: t(`operationForm:${label}`), value }))}
          />
        </div>
      )}
      {fields && operation && Object.entries(operation.schema.inputs).map(([inputName, input]) => (
        <div key={inputName}>
          {input.type !== 'boolean' && (
            <div className='flex items-center'>
              <div className="py-1">
                {t(`operationForm:${input.label}`)}{input.required && <span className="text-error"> *</span>}
              </div>
              <span
                className={'tooltip tooltip-right pl-1 before:w-[200px] before:content-[attr(data-tip)]'}
                data-tip={t(`ToolTipDescription:${input.description}`)}
              >
                <InfoIcon size={16} className="text-slate-400" />
              </span>
            </div>
          )}

          {input.type === 'text' && input.options && !input.multiple && (
            <Select
              color={
                input.required && !input.default && !(selectedField?.operationConfig?.[inputName] as FieldOperationConfigTextInput | undefined)?.text.value
                  ? 'error'
                  : undefined
              }
              size="sm"
              options={[
                { value: '', label: t('Select an option'), disabled: true },
                ...input.options.map(option => ({ ...option }))
              ]}
              value={(selectedField?.operationConfig?.[inputName] as FieldOperationConfigTextInput | undefined)?.text.value ?? (typeof input.default === 'boolean' ? '' : input.default ?? '')}
              onChange={event => {
                controller.onOperationChange(
                  selectedField!.id,
                  selectedField!.operation ?? null,
                  selectedField!.recalculateStrategy,
                  {
                    ...selectedField?.operationConfig,
                    [inputName]: { type: 'text', text: { value: event.target.value } }
                  }
                )
              }}
            />
          )}

          {input.type === 'text' && !input.options && !input.multiple && (
            <FieldOperationTextInput
              input={input}
              inputName={inputName}
              state={state}
              controller={controller}
              fields={fields}
            />
          )}

          {input.type === 'text' && input.multiple && (
            <FieldOperationMultipleTextInput
              input={input}
              inputName={inputName}
              state={state}
              controller={controller}
            />
          )}

          {input.type === 'number' && (
            <FieldOperationNumberInput
              input={input}
              inputName={inputName}
              state={state}
              controller={controller}
            />
          )}

          {input.type === 'chat' && (
            <FieldOperationFieldsInput
              input={input}
              inputName={inputName}
              state={state}
              controller={controller}
              fields={fields}
              type="chat"
            />
          )}

          {input.type === 'file' && (
            <FieldOperationFieldsInput
              input={input}
              inputName={inputName}
              state={state}
              controller={controller}
              fields={fields}
              type="file"
            />
          )}

          {input.type === 'collection' && (
            <FieldOperationCollectionInput
              input={input}
              inputName={inputName}
              state={state}
              controller={controller}
            />
          )}

          {input.type === 'boolean' && (
            <FieldOperationBooleanInput
              input={input}
              inputName={inputName}
              state={state}
              controller={controller}
            />
          )}

          {input.type === 'vector' && (
            <FieldOperationFieldsInput
              input={input}
              inputName={inputName}
              state={state}
              controller={controller}
              fields={fields}
              type="vector"
            />
          )}
        </div>
      ))}
    </div>
  )
}

import { FieldType, UUID } from '@indigohive/cogfy-types'
import { createElement } from 'react'
import { CollectionStateField, CollectionStateRecord, CollectionStateViewField } from '../../lib'
import { CollectionPageController } from '../../pages/CollectionPageV2/collection-page-controller'
import {
  AuthorCell,
  BooleanCell,
  ChatCell,
  CreateDateCell,
  DateCell,
  ErrorCell,
  FileCell,
  JsonCell,
  NumberCell,
  PendingCell,
  ReferenceCell,
  SelectCell,
  StaleIndicator,
  StopwatchCell,
  TextCell,
  UpdateDateCell,
  VectorCell
} from './components'

export type FieldCellType = typeof TextCell

export const FIELD_CELL_BY_TYPE: Record<FieldType, FieldCellType> = {
  author: AuthorCell,
  boolean: BooleanCell,
  chat: ChatCell,
  createDate: CreateDateCell,
  date: DateCell,
  file: FileCell,
  json: JsonCell,
  number: NumberCell,
  reference: ReferenceCell,
  select: SelectCell,
  stopwatch: StopwatchCell,
  text: TextCell,
  updateDate: UpdateDateCell,
  vector: VectorCell
}

export type FieldCellProps = {
  collectionId: UUID
  record: CollectionStateRecord
  field: CollectionStateField
  viewField: CollectionStateViewField
  controller: CollectionPageController
  col: number
  row: number
  options?: { canEdit?: boolean }
}

export function FieldCell (props: FieldCellProps) {
  const { field, record } = props

  const fieldCellComponent = FIELD_CELL_BY_TYPE[field.type]

  const hasError = Boolean(record.properties[field.id]?.error?.message) || Boolean(record.properties[field.id]?.error?.code)
  const isLoading = Boolean(record.properties[field.id]?.pending)
  const isStale = Boolean(record.properties[field.id]?.stale)

  return (
    <>
      {fieldCellComponent && !hasError && !isLoading && createElement(fieldCellComponent, props)}
      {isLoading && !hasError && <PendingCell />}
      {isStale && <StaleIndicator {...props} />}
      {hasError && <ErrorCell {...props} />}
    </>
  )
}

import { useTranslation } from 'react-i18next'
import { ChatMiddlewareDataFormProps } from '../ChatMiddlewareDataFormProps'
import { useState } from 'react'
import { Select } from '../../Select'
import { Input } from '../../Input'

const modelOptions = [
  { label: 'Select a model', value: '', disabled: true },
  { label: 'Claude 3 Sonnet', value: 'anthropic.claude-3-sonnet-20240229-v1:0' },
  { label: 'Claude 3 Haiku', value: 'anthropic.claude-3-haiku-20240307-v1:0' },
  { label: 'Claude 3 Opus', value: 'anthropic.claude-3-opus-20240229-v1:0' },
  { label: 'Claude 3.5 Sonnet', value: 'anthropic.claude-3-5-sonnet-20240620-v1:0' },
  { label: 'Llama 3 8B Instruct', value: 'meta.llama3-8b-instruct-v1:0' },
  { label: 'Llama 3 70B Instruct', value: 'meta.llama3-70b-instruct-v1:0' },
  { label: 'Llama 3.1 8B Instruct', value: 'meta.llama3-1-8b-instruct-v1:0' },
  { label: 'Llama 3.1 70B Instruct', value: 'meta.llama3-1-70b-instruct-v1:0' },
  { label: 'Llama 3.1 405B Instruct', value: 'meta.llama3-1-405b-instruct-v1:0' },
  { label: 'Llama 3.2 11B Instruct', value: 'meta.llama3-2-11b-instruct-v1:0' },
  { label: 'Llama 3.2 90B Instruct', value: 'meta.llama3-2-90b-instruct-v1:0' },
  { label: 'Llama 3.2 1B Instruct', value: 'meta.llama3-2-1b-instruct-v1:0' },
  { label: 'Llama 3.2 3B Instruct', value: 'meta.llama3-2-3b-instruct-v1:0' },
  { label: 'Mistral Large (2402)', value: 'mistral.mistral-large-2402-v1:0' }
]

export function BedrockForm (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props

  const { t } = useTranslation()
  const [model, setModel] = useState<string | null>(
    typeof props.data.model === 'string' ? props.data.model : null
  )
  const [temperature, setTemperature] = useState<number | null>(
    typeof props.data.temperature === 'number' ? props.data.temperature : null
  )

  return (
    <>
      <Select
        label={t('Model')}
        size="sm"
        value={model ?? ''}
        onChange={event => {
          if (event.target.value === '') {
            setModel(null)
            onChange({ ...data, model: null })
          } else {
            setModel(event.target.value)
            onChange({ ...data, model: event.target.value })
          }
        }}
        options={modelOptions}
      />
      <Input
        label={t('Temperature')}
        size="sm"
        type="number"
        min={0}
        max={2}
        step={0.1}
        value={temperature ?? ''}
        onChange={event => {
          if (event.target.value === '') {
            setTemperature(null)
            onChange({ ...data, temperature: null })
          } else {
            setTemperature(parseFloat(event.target.value))
            onChange({ ...data, temperature: parseFloat(event.target.value) })
          }
        }}
      />
    </>
  )
}

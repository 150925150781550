import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PaperclipIcon, SendHorizonalIcon } from 'lucide-react'
import { v4 as uuid } from 'uuid'
import { Button } from '../../../components'
import { ChatMessageFile } from './ChatMessageFile'
import clsx from 'clsx'
import { useCogfy, useToasts } from '../../../hooks'
import { UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'

const MAX_FILES_TO_UPLOAD = 5
const MAX_FILE_SIZE_IN_MB = 20
const MAX_FILE_SIZE_IN_B = MAX_FILE_SIZE_IN_MB * 1024 * 1024

export type ChatInputProps = {
  chatId: UUID
  sendMessageDisabled?: boolean
  onSendChatMessage?: (content: string) => void
  onSubmitFileMessage?: (content: string | null) => void
}

export function ChatInput (props: ChatInputProps) {
  const { chatId, sendMessageDisabled, onSendChatMessage, onSubmitFileMessage } = props

  const ref = useRef<HTMLTextAreaElement>(null)

  const [value, setValue] = useState('')
  const [files, setFiles] = useState<{ id: UUID, file: File }[]>([])
  const { t } = useTranslation()
  const toast = useToasts()
  const cogfy = useCogfy()

  const sendChatMessageFile = useMutation({
    mutationFn: async () => {
      const uploadResult = await cogfy.uploadChatMessageFiles({
        message: {
          chatId,
          content: value
        },
        files: files.map(file => ({
          type: file.file.type,
          size: file.file.size,
          name: file.file.name
        }))
      })

      for (const [index, upload] of uploadResult.files.entries()) {
        const signedUrl = upload.signedUrl
        const body = { ...upload.fields, file: files[index].file }
        const headers = { 'Content-Type': 'multipart/form-data' }

        await axios.post(signedUrl, body, { headers })
      }

      await cogfy.completeUploadChatMessageFiles({ fileIds: uploadResult.files.map(file => file.id) })
    },
    onError: () => toast.error(t('Error on uploading document')),
    onSuccess: async () => {
      setFiles([])
      setValue('')
      onSubmitFileMessage?.(value ?? null)
    }
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault()

    if (submitDisabled || submitLoading) {
      return
    }

    if (files.length === 0) {
      setValue('')
      onSendChatMessage?.(value)
    } else {
      sendChatMessageFile.mutate()
    }
  }

  const handleUploadFiles = (uploadFiles: File[]) => {
    const someFileExceedsLimit = uploadFiles.some(file => file.size > MAX_FILE_SIZE_IN_B)

    if (someFileExceedsLimit) {
      return toast.error(t('File size exceeds the limit of {{maxSize}} MB', { maxSize: MAX_FILE_SIZE_IN_MB }))
    }

    const newFiles = [...files, ...uploadFiles.map(file => ({ id: uuid() as UUID, file }))]

    if (newFiles.length > MAX_FILES_TO_UPLOAD) {
      return toast.error(t('You can only upload up to {{maxFilesToUpload}} files', { maxFilesToUpload: MAX_FILES_TO_UPLOAD }))
    }

    setFiles(newFiles)
  }

  useEffect(() => {
    const handler = () => {
      if (ref.current) {
        ref.current.rows = ref.current.value.split('\n').length
      }
    }

    ref.current?.addEventListener('keyup', handler)

    return () => {
      ref.current?.removeEventListener('keyup', handler)
    }
  }, [ref.current])

  const submitDisabled = (!value.trim() && files.length === 0) || sendMessageDisabled
  const submitLoading = sendChatMessageFile.isPending

  return (
    <div className="flex flex-col w-full max-w-screen-md">
      {files.length > 0 && (
        <div className="overflow-x-auto max-w-screen-md">
          <div className="flex flex-row w-full my-2 px-2 gap-2">
            {files.map(file => (
              <ChatMessageFile
                key={file.id}
                file={{ id: file.id, name: file.file.name, size: file.file.size, type: file.file.type }}
                onDelete={() => setFiles(files.filter(_file => _file.id !== file.id))}
              />
            ))}
          </div>
        </div>
      )}

      <form
        onSubmit={handleSubmit}
        className="flex items-center w-full rounded-[30px] p-1 border border-neutral-content max-w-screen-md bg-base-100 max-h-[25dvh]"
      >
        <label className="btn btn-primary btn-circle btn-ghost">
          <input
            disabled={files.length >= 5}
            type="file"
            className="hidden"
            multiple={true}
            onChange={event => event.target.files && handleUploadFiles(Array.from(event.target.files))}
          />
          <PaperclipIcon
            size={20}
            className={clsx(files.length >= 5 ? 'text-neutral-content' : 'text-primary')}
          />
        </label>

        <textarea
          ref={ref}
          className="textarea resize-none flex-grow px-6 text-gray-700 bg-transparent border-none focus:outline-none h-full"
          value={value}
          placeholder={t('chatInput:Ask to chat')}
          onChange={event => setValue(event.target.value)}
          rows={1}
          onKeyDown={event => {
            if (event.key === 'Enter' && !event.shiftKey && value.trim()) {
              handleSubmit(event)
            }
          }}
        />
        <Button
          type="submit"
          className="self-end"
          disabled={submitDisabled}
          circle
          ghost
          color="primary"
        >
          {submitLoading && <div className="loading loading-spinner loading-md" />}
          {!submitLoading && (
            <SendHorizonalIcon
              size={24}
              className={clsx(submitDisabled ? 'text-neutral-content' : 'text-primary')}
            />
          )}
        </Button>
      </form>
    </div>
  )
}

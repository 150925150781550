import { XIcon } from 'lucide-react'
import { ReferenceFieldData, ReferenceRecordProperty } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from '../../../components'
import { useWorkspaceSlug } from '../../../hooks'
import { FieldCellProps } from '../FieldCell'

export function ReferenceCell (props: FieldCellProps) {
  const { record, field, viewField, controller } = props
  const workspace = useWorkspaceSlug()
  const { t } = useTranslation()

  const limit = (field.data as ReferenceFieldData)?.reference?.limit
  const referenceResult = record.properties[field.id] as ReferenceRecordProperty
  const references = referenceResult?.reference?.value
  const fieldData = field.data as ReferenceFieldData

  const collectionId = fieldData?.reference?.collectionId

  const wrap = viewField.config?.ui?.textWrap

  return (
    <div
      className={clsx(
        'wrap px-2 py-1',
        wrap && 'w-full whitespace-pre-wrap'
      )}
    >
      <div
        className={clsx(
          'flex gap-x-1',
          wrap && 'flex-wrap'
        )}
      >
        {limit && references && references.length > 0 && (
          <Link
            key={references[0]?.id}
            to={`/${workspace}/${collectionId}`}
            className="badge w-fit justify-between items-center bg-slate-50 cursor-pointer truncate my-1"
          >
            <span>
              {(!references[0]?.title || references[0]?.title === '') ? t('Unnamed') : references[0]?.title}
            </span>
            <Button
              size="xs"
              ghost
              circle
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                controller.onDeleteRecordReferenceClick(record.id, field.id, references[0].id)
              }}
            >
              <XIcon size={14} />
            </Button>
          </Link>
        )}
        {!limit && references?.map(reference => (
          <Link
            key={reference?.id}
            to={`/${workspace}/${collectionId}`}
            className="badge w-fit justify-between items-center bg-slate-50 cursor-pointer truncate my-1"
          >
            <span>
              {(!reference?.title || reference?.title === '') ? t('Unnamed') : reference?.title}
            </span>
            <Button
              size="xs"
              ghost
              circle
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                controller.onDeleteRecordReferenceClick(record.id, field.id, reference.id)
              }}
            >
              <XIcon size={12} />
            </Button>
          </Link>
        ))}
      </div>
    </div>
  )
}

import { ChatLastMessage, ChatProvider } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { isBefore, isToday, isYesterday, startOfYesterday } from 'date-fns'
import { t } from 'i18next'
import { siWhatsapp } from 'simple-icons'
import { FormattedDate, SimpleIcon } from '../../../components'
import { useAuthentication } from '../../../hooks'
import { ChatMessageContentPreview, ChatMessageContentType } from '../../../components/ChatMessageContentPreview'

export type PreviewChatContentProps = {
  onClick: () => void
  textWrap?: boolean | null
  date?: string | null
  lastMessage: ChatLastMessage
  phone?: string | null
  chatProvider?: ChatProvider
}

export function PreviewChatContent (props: PreviewChatContentProps) {
  const { onClick, textWrap, date, lastMessage, phone, chatProvider } = props

  const auth = useAuthentication()
  const messageSender = getMessageSender(lastMessage, auth.authenticatedUser?.id, phone)

  return (
    <div
      onClick={onClick}
      className={clsx(
        'h-full',
        'w-full',
        'px-2',
        'py-1',
        'hover:bg-base-200',
        'active:bg-base-300',
        'text-gray-600'
      )}
    >
      {chatProvider === 'whatsapp' && (
        <div className="inline-flex items-center gap-1 text-xs">
          <SimpleIcon
            icon={siWhatsapp}
            size={12}
          />
          <span>WhatsApp</span>
        </div>
      )}
      <div className="flex items-center">
        <div className="grow font-medium text-gray-600 inline-flex gap-1.5 items-center mr-1">
          {messageSender}
        </div>
        <div className="text-xs text-gray-500">
          {date && isToday(date) && <FormattedDate date={date} format='p' />}
          {date && isYesterday(date) && t('Yesterday')}
          {date && isBefore(date, startOfYesterday()) && <FormattedDate date={date} format='P' />}
        </div>
      </div>
      <ChatMessageContentPreview
        content={lastMessage.content}
        contentType={(lastMessage.contentType ?? 'text') as ChatMessageContentType}
        contentData={lastMessage.contentData}
        textWrap={textWrap}
      />
    </div>
  )
}

function getMessageSender (lastMessage: ChatLastMessage, userId: any, phone?: string | null): string {
  if (lastMessage?.role === 'assistant' && phone) {
    return phone
  }

  if (lastMessage?.user?.id === userId) {
    return t('You')
  }

  if (lastMessage?.user?.name) {
    return lastMessage.user.name
  }

  return t('Assistant')
}

import { Input } from '../../Input'
import { Select } from '../../Select'
import { ChatMiddlewareConditionSource } from '../ChatMiddlewareConditionForm'

export type ChatMiddlewareConditionValueInputProps = {
  source: ChatMiddlewareConditionSource
  value: string | number | boolean | null
  onChange: (value: string | number | boolean | null) => void
}

export function ChatMiddlewareConditionValueInput (props: ChatMiddlewareConditionValueInputProps) {
  const { source, value, onChange } = props

  return (
    <div>
      {source.type === 'content_type' && (
        <Select
          size="sm"
          value={value as string}
          onChange={event => { onChange(event.target.value) }}
          options={[
            { value: 'audio', label: 'Audio' },
            { value: 'contacts', label: 'Contacts' },
            { value: 'document', label: 'Document' },
            { value: 'image', label: 'Image' },
            { value: 'location', label: 'Location' },
            { value: 'sticker', label: 'Sticker' },
            { value: 'text', label: 'Text' },
            { value: 'video', label: 'Video' }
          ]}
        />
      )}
      {source.type === 'content' && (
        <Input
          size="sm"
          value={value as string}
          onChange={event => { onChange(event.target.value) }}
        />
      )}
      {source.type === 'field' && (
        <>
          {source.field.type === 'boolean' && (
            <Select
              size="sm"
              value={value as string}
              onChange={event => { onChange(event.target.value) }}
              options={[
                { value: 'true', label: 'True' },
                { value: 'false', label: 'False' }
              ]}
            />
          )}
          {source.field.type !== 'boolean' && (
            <Input
              size="sm"
              value={value as string}
              onChange={event => { onChange(event.target.value) }}
            />
          )}
        </>
      )}
    </div>
  )
}

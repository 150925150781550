import {
  CreateConnectionWhatsAppCommand,
  GetConnectionModelsResult,
  GetConnectionsQuery,
  GetConnectionsResult,
  GetWhatsAppNumbersResult,
  RegisterWhatsAppNumberCommand,
  UpdateConnectionDefaultCommand,
  UpdateConnectionEnabledCommand,
  UpdateWhatsAppNumberWebhookCommand,
  UUID
} from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class ConnectionsClient extends BaseClient {
  async createWhatsApp (
    data: CreateConnectionWhatsAppCommand
  ): Promise<void> {
    await this.axios.post('/connections/whatsapp', data)
  }

  async getList (
    params: GetConnectionsQuery = {},
    signal?: AbortSignal
  ): Promise<GetConnectionsResult> {
    return (await this.axios.get('/connections', { params, signal })).data
  }

  async getModels (
    connectionId: UUID,
    signal?: AbortSignal
  ): Promise<GetConnectionModelsResult> {
    return (await this.axios.get(`/connections/${connectionId}/models`, { signal })).data
  }

  async getWhatsAppNumbers (
    connectionId: UUID,
    signal?: AbortSignal
  ): Promise<GetWhatsAppNumbersResult> {
    return (await this.axios.get(`/connections/${connectionId}/whatsapp/numbers`, { signal })).data
  }

  async registerWhatsAppNumber (
    connectionId: UUID,
    data: RegisterWhatsAppNumberCommand
  ): Promise<void> {
    await this.axios.post(`/connections/${connectionId}/whatsapp/register`, data)
  }

  async updateEnabled (
    connectionId: UUID,
    data: UpdateConnectionEnabledCommand
  ): Promise<void> {
    await this.axios.patch(`/connections/${connectionId}/enabled`, data)
  }

  async updateDefault (
    connectionId: UUID,
    data: UpdateConnectionDefaultCommand
  ): Promise<void> {
    await this.axios.patch(`/connections/${connectionId}/default`, data)
  }

  async updateWhatsAppNumberWebhook (
    connectionId: UUID,
    data: UpdateWhatsAppNumberWebhookCommand
  ): Promise<void> {
    await this.axios.post(`/connections/${connectionId}/whatsapp/webhook`, data)
  }
}

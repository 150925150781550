import { PlusIcon } from 'lucide-react'
import { Button, Input } from '../../../components'
import axios from 'axios'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useCogfy, useToasts } from '../../../hooks'

const dialogId = 'add_openai_dialog'

export type AddOpenAIButtonProps = {
  onSuccess?: () => void
}

export function AddOpenAIButton (props: AddOpenAIButtonProps) {
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const toasts = useToasts()
  const formik = useFormik({
    initialValues: {
      name: '',
      apiKey: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field')),
      apiKey: yup.string().required(t('Required field'))
    }),
    onSubmit: async (values, helpers) => {
      try {
        await axios.get<{ scopes: string[] }>(
          'https://api.openai.com/v1/models',
          {
            headers: {
              Authorization: `Bearer ${values.apiKey}`
            }
          }
        )
      } catch (error) {
        helpers.setFieldError('apiKey', 'Invalid API key')
        return
      }

      try {
        await cogfy.createConnection({
          provider: 'openai',
          name: values.name,
          values: [
            { name: 'api_key', value: values.apiKey }
          ]
        })
        props.onSuccess?.()
        const dialog = document.getElementById(dialogId) as HTMLDialogElement
        dialog.close()
        helpers.resetForm()
      } catch {
        toasts.error(t('Unexpected error'))
      }
    }
  })

  return (
    <>
      <Button
        color="primary"
        size="sm"
        onClick={() => {
          const dialog = document.getElementById(dialogId) as HTMLDialogElement
          dialog.showModal()
        }}
      >
        <PlusIcon size={16} />
        {t('WorkspaceConnectionsPage:Connect')}
      </Button>
      <dialog id={dialogId} className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            {t('WorkspaceConnectionsPage:Connect an OpenAI account')}
          </h3>

          <form
            id={dialogId + '_form'}
            className="flex flex-col gap-4 my-4"
            onSubmit={formik.handleSubmit}
          >
            <Input
              label={t('WorkspaceConnectionsPage:Connection name')}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              {...formik.getFieldProps('name')}
            />

            <Input
              type="password"
              label="API Key"
              error={Boolean(formik.touched.apiKey && formik.errors.apiKey)}
              helperText={formik.touched.apiKey && formik.errors.apiKey}
              {...formik.getFieldProps('apiKey')}
            />
          </form>

          <form method="dialog" id={dialogId + '_close_button'}>
            <div className="modal-action">
              <Button
                size="sm"
                form={dialogId + '_close_button'}
                disabled={formik.isSubmitting}
                onClick={() => {
                  formik.resetForm()
                }}
              >
                {t('Close')}
              </Button>
              <Button
                size="sm"
                color="primary"
                form={dialogId + '_form'}
                disabled={formik.isSubmitting}
              >
                {t('Confirm')}
              </Button>
            </div>
          </form>
        </div>
      </dialog>
    </>
  )
}

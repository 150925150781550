import { FieldType } from '@indigohive/cogfy-types'
import { createElement, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu } from '../../../../components'
import { FIELD_ICONS_BY_TYPE } from '../../../../field-types'

export type CreateFieldMenuProps = {
  onClick?: (fieldType: FieldType) => void
}

const FIELD_TYPES_BY_CATEGORY: FieldType[][] = [
  [
    'text',
    'number',
    'boolean',
    'select',
    'date',
    'file',
    'reference'
  ],
  [
    'chat'
  ],
  [
    'author',
    'createDate',
    'updateDate'
  ],
  [
    'json',
    'vector'
  ]
]

export function CreateFieldMenu (props: CreateFieldMenuProps) {
  const { t } = useTranslation()

  return (
    <Menu>
      <li className="menu-title">
        {t('New field')}
      </li>
      {FIELD_TYPES_BY_CATEGORY.map((category, index) => (
        <Fragment key={index}>
          {category.map(fieldType =>
            <li key={fieldType}>
              <a onClick={() => props.onClick?.(fieldType)}>
                {createElement(
                  FIELD_ICONS_BY_TYPE[fieldType],
                  { size: 16 }
                )}
                {t(`fieldType:${fieldType}`, fieldType)}
              </a>
            </li>
          )}
          {index < FIELD_TYPES_BY_CATEGORY.length - 1 && (
            <div className="divider h-1 my-1" />
          )}
        </Fragment>
      ))}
    </Menu>
  )
}

import { ChatMiddlewareType } from '@indigohive/cogfy-types'
import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { OverlayContent } from '../../../../../../components'
import { ChatMiddlewareIcon } from '../../../../../../components/ChatMiddlewareIcon'

export type AddMiddlewareMenuProps = {
  anchorEl?: HTMLElement | null
  onClose: () => void
  onAddMiddlewareClick: (type: ChatMiddlewareType) => void
}

const MIDDLEWARE_OPTIONS: Partial<Record<ChatMiddlewareType, { label: ReactNode }>>[] = [
  {
    reply: { label: 'Reply' },
    transform: { label: 'Transform' },
    action: { label: 'Action' },
    http: { label: 'HTTP' },
    group: { label: 'Group' },
    stop: { label: 'Stop' }
  },
  {
    instructions: { label: 'Instructions' },
    history: { label: 'History' },
    retrieval: { label: 'Retrieval' },
    tool: { label: 'Tool' },
    bedrock: { label: 'AWS Bedrock' },
    openai: { label: 'OpenAI' }
  },
  {
    whatsapp: { label: 'WhatsApp' }
  }
]

export function AddChatMiddlewareContextMenu (props: AddMiddlewareMenuProps) {
  const { anchorEl, onClose, onAddMiddlewareClick } = props
  const { t } = useTranslation()

  return (
    <OverlayContent
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={onClose}
    >
      <ul className="menu min-w-64 shadow-lg border bg-base-100 rounded-box">
        <li className="menu-title">
          {t('Add middleware')}
        </li>
        {MIDDLEWARE_OPTIONS.map((options, index) => (
          <Fragment key={index}>
            {Object.entries(options).map(([type, option]) => (
              <li key={type} onClick={() => onAddMiddlewareClick(type as ChatMiddlewareType)}>
                <a>
                  <ChatMiddlewareIcon type={type as ChatMiddlewareType} size={16} />
                  {option.label}
                </a>
              </li>
            ))}
            {index < MIDDLEWARE_OPTIONS.length - 1 && (
              <div className="divider my-0 py-0" />
            )}
          </Fragment>
        ))}
      </ul>
    </OverlayContent>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useCogfy } from '../../../../../../hooks'
import { UpdateChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/updateChatMiddleware'
import { FieldType, UUID } from '@indigohive/cogfy-types'
import { GetChatMiddlewaresResultData } from '@indigohive/cogfy-types/endpoints/getChatMiddlewares'
import { ChatMiddlewareDataForm } from '../../../../../../components/ChatMiddlewareDataForm'
import { useTranslation } from 'react-i18next'
import { GetCollectionsResultData } from '@indigohive/cogfy-types/endpoints/getCollections'
import { ChatMiddlewareConditionForm } from '../../../../../../components/ChatMiddlewareConditionForm'

export type EditChatMiddlewareFormProps = {
  fieldId: UUID
  middleware: GetChatMiddlewaresResultData
  fields?: {
    id: string
    name: string
    type: FieldType
  }[]
  collections?: GetCollectionsResultData[]
}

export function EditChatMiddlewareForm (props: EditChatMiddlewareFormProps) {
  const { fieldId, middleware } = props
  const [name, setName] = useState(middleware.name)
  const [data, setData] = useState(middleware.data)
  const [condition, setCondition] = useState(middleware.condition)

  const { t } = useTranslation()
  const client = useCogfy()
  const queryClient = useQueryClient()
  const updateMiddleware = useMutation({
    mutationFn: (data: UpdateChatMiddlewareCommand) => client.updateChatMiddleware(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getChatMiddlewares', { fieldId }]
      })
    }
  })
  const update = useDebouncedCallback(() => {
    updateMiddleware.mutate({
      chatMiddlewareId: middleware.id,
      name,
      data,
      condition
    })
  }, 300)

  return (
    <div>
      <label className="form-control w-full my-4">
        <div className="label">
          <span className="label-text">{t('Name')}</span>
        </div>
        <input
          className="input input-bordered w-full input-sm"
          value={name}
          onBlur={() => {
            update()
          }}
          onChange={event => {
            setName(event.target.value)
            update()
          }}
        />
      </label>

      <ChatMiddlewareConditionForm
        fields={props.fields}
        value={condition}
        onChange={newCondition => {
          setCondition(newCondition)
          update()
        }}
      />

      <ChatMiddlewareDataForm
        type={middleware.type}
        data={data}
        onChange={newData => {
          setData(newData)
          update()
        }}
        fields={props.fields}
        collections={props.collections}
      />
    </div>
  )
}

import { Calendar, InfoIcon, KeyRoundIcon, PlusIcon, Tag, Trash2 } from 'lucide-react'
import { UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Container, FormattedDate, SettingsPagesHeader } from '../../components'
import { useApiKeysPage, useCogfy, usePermissions, useToasts, useWorkspaceSlug, useTitle } from '../../hooks'
import { Trans, useTranslation } from 'react-i18next'

export function ApiKeysIndexPage () {
  const getApiKeysPage = useApiKeysPage({ pageSize: 100 })
  const location = useLocation()
  const cogfy = useCogfy()
  const workspaceSlug = useWorkspaceSlug()
  const toasts = useToasts()
  const permissions = usePermissions()
  const deleteApiKey = useMutation({
    mutationFn: async (apiKeyId: UUID) => {
      await cogfy.deleteApiKey({ apiKeyId })
      toasts.info(t('API Key deleted'))
      getApiKeysPage.refetch()
    }
  })
  const canCreateApiKey = permissions.hasPermission?.('api-key:create', null)
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      window.history.replaceState({}, '')
    }
  })

  useTitle({
    title: t('API Keys'),
    loading: getApiKeysPage.isLoading,
    error: getApiKeysPage.isError
  })

  return (
    <>
      <SettingsPagesHeader
        title={t('API Keys')}
        description={(
          <span>
            <Trans
              i18nKey={'WorkspaceAPIKeysPage:Create and manage your API keys to safely access integrations. See more at Cogfy Docs.'}
              components={{
                url: (
                  <a
                    className="link link-primary"
                    href="https://docs.cogfy.com/api-reference/authentication"
                    target="_blank"
                    rel="noreferrer"
                  />
                )
              }}
            />
          </span>
        )}
      >
        <KeyRoundIcon size={46} />
      </SettingsPagesHeader>
      <Container>
        <div className="flex mt-8 justify-end">
          {canCreateApiKey && (
            <Button
              size="sm"
              color="primary"
              to={`/${workspaceSlug}/api-keys/create`}
            >
              {t('New API Key')}<PlusIcon size={16} />
            </Button>
          )}
        </div>
        {location.state?.createdApiKey && (
          <div role="alert" className="alert bg-base-100 my-4 shadow-sm">
            <InfoIcon size={24} className="text-info" />
            <div>
              <h3 className="font-bold">{t('API Key created')}</h3>
              <div className="text-xs">
                {location.state.createdApiKey?.plainTextKey}
              </div>
            </div>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                navigator.clipboard.writeText(location.state.createdApiKey?.plainTextKey)
              }}
            >
              {t('Copy')}
            </Button>
          </div>
        )}
        {getApiKeysPage.isLoading && <progress className="progress w-full" />}
        {!getApiKeysPage.isLoading && (
          <div className="overflow-x-auto rounded-lg mt-4">
            <table
              className="table"
              style={{ borderCollapse: 'separate', borderSpacing: 0 }}
            >
              <thead className='bg-background-default'>
                <tr>
                  <th className='w-[40px]' />
                  <th className='w-[428px]'>
                    <div className='settings-table-header-element'>
                      <Tag size={14} />
                      {t('Name')}
                    </div>
                  </th>
                  <th className='w-[428px]'>
                    <div className='settings-table-header-element'>
                      <Calendar size={14} />
                      {t('Creation date')}
                    </div>
                  </th>
                  <th className='w-[80px]'></th>
                </tr>
              </thead>
              <tbody>
                {!getApiKeysPage.data && (
                  <>
                    <tr>
                      <td colSpan={2} className='py-6 pl-12'>{t('Error when searching API keys')}</td>
                    </tr>
                    <tr>
                      <td colSpan={4} className='p-0'>
                        <div className='divider my-0' />
                      </td>
                    </tr>
                  </>
                )}
                {getApiKeysPage.data && (
                  <>
                    {getApiKeysPage.data.data.length === 0 && (
                      <>
                        <tr>
                          <td colSpan={2} className='py-6 pl-12'>{t('No API keys found')}</td>
                        </tr>
                        <tr>
                          <td colSpan={4} className='p-0'>
                            <div className='divider my-0' />
                          </td>
                        </tr>
                      </>
                    )}
                    {getApiKeysPage.data.data.map(apiKey => (
                      <>
                        <tr key={apiKey.id} className='h-16'>
                          <td />
                          <td className={clsx(!apiKey.name && 'text-gray-400')}>
                            {apiKey.name ?? t('Unnamed')}
                          </td>
                          <td className="w-[428px]">
                            <FormattedDate date={apiKey.createDate} format="Ppp" />
                          </td>
                          <td className="w-[80px]">
                            <button
                              className='hover:border hover:rounded-lg hover:bg-background-default w-7 h-7 flex items-center justify-center'
                              disabled={deleteApiKey.isPending}
                              onClick={() => deleteApiKey.mutate(apiKey.id)}
                            >
                              <Trash2 size={16} />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} className='p-0'>
                            <div className='divider my-0' />
                          </td>
                        </tr>
                      </>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        )}
      </Container >
    </>
  )
}

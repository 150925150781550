import {
  BracesIcon,
  CalendarFoldIcon,
  CalendarIcon,
  CalendarPlusIcon,
  ChevronsLeftRightEllipsisIcon,
  CircleUserIcon,
  HashIcon,
  ListIcon,
  MessagesSquareIcon,
  MoveUpRightIcon,
  PaperclipIcon,
  SquareCheckIcon,
  TextIcon,
  TimerIcon
} from 'lucide-react'
import { FieldType } from '@indigohive/cogfy-types'

type IconComponentType = typeof CircleUserIcon

export const FIELD_ICONS_BY_TYPE: Record<FieldType, IconComponentType> = {
  author: CircleUserIcon,
  boolean: SquareCheckIcon,
  chat: MessagesSquareIcon,
  createDate: CalendarPlusIcon,
  date: CalendarIcon,
  file: PaperclipIcon,
  json: BracesIcon,
  number: HashIcon,
  reference: MoveUpRightIcon,
  select: ListIcon,
  stopwatch: TimerIcon,
  text: TextIcon,
  updateDate: CalendarFoldIcon,
  vector: ChevronsLeftRightEllipsisIcon
}

import { ChatMiddlewareType } from '@indigohive/cogfy-types'
import { useState } from 'react'
import { ChatMiddlewareDataFormProps } from './ChatMiddlewareDataFormProps'
import { ActionForm } from './subcomponents/ActionForm'
import { BedrockForm } from './subcomponents/BedrockForm'
import { DefaultForm } from './subcomponents/DefaultForm'
import { HistoryForm } from './subcomponents/HistoryForm'
import { HttpForm } from './subcomponents/HttpForm'
import { InstructionsForm } from './subcomponents/InstructionsForm'
import { OpenAiForm } from './subcomponents/OpenAiForm'
import { ReplyForm } from './subcomponents/ReplyForm'
import { RetrievalForm } from './subcomponents/RetrievalForm'
import { TransformForm } from './subcomponents/TransformForm'
import { ToolForm } from './subcomponents/ToolForm'

const COMPONENTS_BY_MIDDLEWARE_TYPE: Partial<Record<ChatMiddlewareType, typeof InstructionsForm>> = {
  action: ActionForm,
  bedrock: BedrockForm,
  history: HistoryForm,
  http: HttpForm,
  instructions: InstructionsForm,
  openai: OpenAiForm,
  reply: ReplyForm,
  retrieval: RetrievalForm,
  transform: TransformForm,
  tool: ToolForm
}

export function ChatMiddlewareDataForm (props: ChatMiddlewareDataFormProps) {
  const { type } = props
  const [jsonMode, setJsonMode] = useState(false)

  const CustomComponent = COMPONENTS_BY_MIDDLEWARE_TYPE[type]

  return (
    <>
      {CustomComponent && (
        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text">JSON editor</span>
            <input
              type="checkbox"
              className="toggle toggle-primary"
              checked={jsonMode}
              onChange={() => setJsonMode(!jsonMode)}
            />
          </label>
        </div>
      )}
      {CustomComponent && !jsonMode && <CustomComponent {...props} />}
      {(!CustomComponent || jsonMode) && <DefaultForm {...props} />}
    </>
  )
}

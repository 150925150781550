import { useState } from 'react'
import { Select } from '../../Select'
import { ChatMiddlewareDataFormProps } from '../ChatMiddlewareDataFormProps'

const inputOptions = [
  { label: 'Select a transform type', value: '', disabled: true },
  { label: 'Transcribe', value: 'transcribe' },
  { label: 'Text to speech', value: 'generateAudio' }
]

export function TransformForm (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props

  const [transformType, setTransformType] = useState(data?.name ?? '')

  return (
    <Select
      label="Transform type"
      size="sm"
      options={inputOptions}
      value={transformType}
      onChange={event => {
        setTransformType(event.target.value)
        onChange({
          ...data,
          name: event.target.value
        })
      }}
    />
  )
}
